<template>
  <div>
    <section class="w3l-services2">
      <div class="story-inner-6 text-left py-5">
        <div class="container pt-lg-3">
          <div class="header-section text-center">
            <h3>展望未来</h3>
            <p class="mt-3 mb-5">服务更多企业，实现更大的抱负</p>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6 story-gd">
              <div class="header-section">
                <h3 class="title">帮助更多企业实现产业升级</h3>
              </div>
              <p class="mb-4">我们致力于传统制造业的生产自动化信息化改造，帮助企业降低成本，提升竞争力。</p>
              <ul class="services-list mb-md-5 mb-0">
                <li>产品与解决方案</li>
                <li>定制型解决方案</li>
                <li>减少能耗可靠运行</li>
                <li>改善设备操作条件</li>
                <li>降低企业自身资源消耗</li>
              </ul>
            </div>
            <div class="col-md-5 offset-md-1 offset-0 mt-md-0 mt-5">
              <div class="story-gd proff">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w3l-services3">
      <div class="story-inner-6 text-left py-5">
        <div class="container pt-lg-3">
          <div class="row align-items-center">
            <div class="col-md-5">
              <div class="story-gd proff">
              </div>
            </div>
            <div class="col-md-6 offset-md-1 offset-0 story-gd mt-md-0 mt-5">
              <div class="header-section">
                <h3 class="title">为客户创造独特价值</h3>
              </div>
              <p class="mb-4">换位思考，站在客户的角度看问题解决问题，产品和服务应为客户的实际需要而改变。</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w3l-services4">
      <div class="story-inner-6 text-left py-5">
        <div class="container pt-lg-3">
          <div class="row align-items-center">
            <div class="col-md-6 story-gd">
              <div class="header-section">
                <h3 class="title">时刻在线，心系客户</h3>
              </div>
              <p class="mb-4">您可通过多种渠道与我们的客服部门联系，随时解决您的燃眉之急。</p>
              <ul class="services-list mb-md-5 mb-0">
                <li>电话（工作日）</li>
                <li>工作人员微信</li>
                <li>本网站留言</li>
                <li>“有数可据”微信小程序客服功能</li>
                <li>亲临我司</li>
              </ul>
            </div>
            <div class="col-md-5 offset-md-1 offset-0 mt-md-0 mt-5">
              <div class="story-gd proff">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Story"
}
</script>

<style scoped>

</style>
