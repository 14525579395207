<template>
  <div>
    <header-section />
    <about-link />
    <story />
  </div>
</template>

<script>
import {
  HeaderSection,
  AboutLink,
  Story
} from './components'

export default {
  name: 'Services',
  components: {
    HeaderSection,
    AboutLink,
    Story
  },
  data() {
    return {
      title: '服务 - 珠海市星舰科技有限公司'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      document.title = this.title
    }
  }
}
</script>

<style scoped>

</style>
