<template>
  <section class="w3l-services1">
    <div class="header-section text-center">
      <h2>服务宗旨</h2>
      <p class="mt-3 mb-0">本公司理念“诚信为本、客户至上、质量优先、互利双赢”。本公司致力于解决企业转型的痛点，推动企业的升级改造，为2025中国制造出一份力。</p>
    </div>
    <div class="feature-16-main py-5">
      <div class="container pb-lg-3">
        <div class="features-grids">
          <div class="row">
            <div class="col-md-6 service1-grid">
              <div class="feature-16-gd service1">
                <div class="icon">
                  <img src="https://o.stjan.cn/assets/images/service1.png" class="img-fluid" alt="" />
                </div>
                <div class="feature-16-gd-info">
                  <h4 class="mt-4 mb-2">自动化生产线</h4>
                  <p>为企业量身打造的产业升级“利器”</p>
                  <ul>
                    <li>实地调研，制定可行方案</li>
                    <li>安装调试期间，专业人员全程负责</li>
                    <li>验收后，提供全天候客户服务</li>
                  </ul>
                  <a href="#url" class="btn btn-primary theme-button mt-4">了解详情</a>
                </div>
              </div>
            </div>
            <div class="col-md-6 service1-grid">
              <div class="feature-16-gd service2">
                <div class="icon">
                  <img src="https://o.stjan.cn/assets/images/service2.png" class="img-fluid" alt="" />
                </div>
                <div class="feature-16-gd-info">
                  <h4 class="mt-4 mb-2">物联网产品</h4>
                  <p>标准化产品，提供数据采集和远程控制的功能。</p>
                  <ul>
                    <li>售前咨询</li>
                    <li>售后服务</li>
                    <li>提供备品供用户更换</li>
                  </ul>
                  <a href="#url" class="btn btn-primary theme-button mt-4">了解详情</a>
                </div>
              </div>
            </div>
            <div class="col-md-6 service1-grid">
              <div class="feature-16-gd service3">
                <div class="icon">
                  <img src="https://o.stjan.cn/assets/images/service3.png" class="img-fluid" alt="" />
                </div>
                <div class="feature-16-gd-info">
                  <h4 class="mt-4 mb-2">生产管理系统</h4>
                  <p>根据企业的自身特点，开发便于管理者使用的后台系统，优化企业效益</p>
                  <ul>
                    <li>实地调研，从用户角度出发，解决用户痛点</li>
                    <li>硬件和软件配置调试全程跟踪</li>
                    <li>可根据用户需求二次开发</li>
                  </ul>
                  <a href="#url" class="btn btn-primary theme-button mt-4">了解详情</a>
                </div>
              </div>
            </div>
            <div class="col-md-6 service1-grid">
              <div class="feature-16-gd service4">
                <div class="icon">
                  <img src="https://o.stjan.cn/assets/images/service4.png" class="img-fluid" alt="" />
                </div>
                <div class="feature-16-gd-info">
                  <h4 class="mt-4 mb-2">“有数可据”小程序</h4>
                  <p>便于用户管理的重量级应用，无需安装，即点即用</p>
                  <ul>
                    <li>监视重要数据，远程控制</li>
                    <li>全天候联系客服</li>
                    <li>了解更多的信息</li>
                  </ul>
                  <a href="#url" class="btn btn-primary theme-button mt-4">了解详情</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeaderSection"
}
</script>

<style scoped>

</style>
